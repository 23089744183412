<template>
    <div class="login-view">
      <div class="login-view-wrapper">
        <div class="login-view-left">
          <div class="stick"></div>
          <h1>Кириш</h1>
          <p>Давом эттириш учун, киринг</p>
          <div class="login-view-card">
            <ValidationObserver ref="provider">
  
              <el-form :model="auth" :rules="rules" ref="auth" class="Form">
                  <ValidationProvider rules="required" v-slot="{ errors, validated }">
                    <div class="inp_wrapper"
                      :class="{ 'is-error': errors.length, 'inp_container': validated && !errors.length }">
                      <input :placeholder="$t('login')" class="real_inp" type="text" name="login_name" id="login_inp"
                        v-model="auth.login_name" @keyup.enter="login()">
                      <div class="left_inpContent" :class="{ 'left_inpContentError': errors.length }"></div>
                    </div>
                  </ValidationProvider>
  
                  <ValidationProvider rules="required" v-slot="{ errors}">
                    <div class="inp_wrapper" :class="{ 'is-error': errors.length, 'inp_container': !errors.length }">
                      <img v-if="!showPassword" @click="togglePasswordVisibility" class="eye_open"
                        src="../../assets/img/eye_password1.svg" alt="">
                      <img v-else @click="togglePasswordVisibility" class="eye_close"
                        src="../../assets/img/eye_password.svg" alt="">
                      <input class="real_inp" name="password" v-model="auth.password" @keyup.enter="login()"
                        :type="showPassword ? 'text' : 'password'" :placeholder="$t('password')">
                      <div class="left_inpContent" :class="{ 'left_inpContentError': errors.length }"></div>
                    </div>
                  </ValidationProvider>
  
              </el-form>
              <div class="login-view-button">
                <button class="login-enter-button" @click="login()" id="login-button" :disabled="prevent">
                  {{ $t("enter") }}
                  <i v-if="!prevent" class="el-icon-right ml-1"></i>
                  <i v-else class="el-icon-loading ml-1"></i>
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
  
        <div class="login-view-right">
          <div class="login-view-container">
            <div class="login-view-card-container-cover">
              <div>
                <img id="emblem" src="@/assets/img/emblem.svg" alt="" />
              </div>
              <h1>PASPORTBINO</h1>
              <p>
                Ўзбекистон Республикасида сейсмик фаол ҳудудларида жойлашган бино ва
                иншоотларнинг электрон техник паспортларини шакллантириш тизими
              </p>
            </div>
  
            <div class="login-view_img">
              <img src="@/assets/img/building_loginPage.svg" alt="Login_building">
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  export default {
    components: {
      ValidationProvider, ValidationObserver
    },
    data() {
      return {
        showPassword: false,
        now_day: "",
        back_ver: "1.0.0",
        now_time: "",
        auth: {
          login_name: null,
          password: null,
        },
        loading: false,
        current_district: [],
        rules: {
          login_name: [
            {
              required: true,
              message: this.$t("add_login"),
              trigger: "change",
            },
          ],
          password: [
            {
              required: true,
              message: this.$t("add_password"),
              trigger: "change",
            },
          ],
        },
        loading2: false,
        prevent: false,
      };
    },
  
    computed: {
      dictionary() {
        return this.$store.state.dictionary;
      },
    },
    mounted() {
      setInterval(() => {
        var date = new Date();
        this.now_day =
          date.getFullYear() +
          "." +
          (date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "." +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());
        this.now_time =
          date.getHours() +
          ":" +
          (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()) +
          ":" +
          (date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds());
      }, 1000);
    },
    methods: {
  
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword
      },
  
      changeLeftContent() {
        const inpLogin = document.querySelector('.left_inpContent')
        if (inpLogin) {
          inpLogin.style.transform = 'translate(0, 0)';
        }
  
      },
      confirmation() {
        this.$refs.provider.validate().then((valid) => {
          if (valid) {
            this.login()
          }
        })
      },
      onImgLoad() {
        this.loading = true;
      },
      login() {
        console.log('sdfgsd');
        this.$refs.provider.validate().then((valid) => {
          if (valid) {
            this.prevent = true;
            axios
              .post("/token/", this.auth)
              .then((response) => {
                localStorage.token = response.data.access;
                localStorage.refresh = response.data.refresh;
                localStorage.role = response.data.role;
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${localStorage.token}`;
                const role = response.data.role;
                this.$store.dispatch("get_me");
                this.$store.dispatch("get_version");
                this.$store.commit("set_role", role);
                console.log(role);
                if (role === "reservoir") {
                  if (this.$router.path != "/") {
                    this.$router.push("/").catch((e) => {
                      if (
                        e.name !== "NavigationDuplicated" &&
                        !e.message.includes(
                          "Avoided redundant navigation to current location"
                        )
                      ) {
                        console.log(e);
                      }
                    });
                  }
                }
                if (role === "admin") {
                  if (this.$router.path != "/") {
                    this.$router.push("/").catch((e) => {
                      if (
                        e.name !== "NavigationDuplicated" &&
                        !e.message.includes(
                          "Avoided redundant navigation to current location"
                        )
                      ) {
                        console.log(e);
                      }
                    });
                  }
                }
                if (role === "ministry") {
                  if (this.$router.path != "/") {
                    this.$router.push("/").catch((e) => {
                      if (
                        e.name !== "NavigationDuplicated" &&
                        !e.message.includes(
                          "Avoided redundant navigation to current location"
                        )
                      ) {
                        console.log(e);
                      }
                    });
                  }
                }
                if (role === "tf_operator") {
                  this.$router.push("/transport").catch((e) => {
                    if (
                      e.name !== "NavigationDuplicated" &&
                      !e.message.includes(
                        "Avoided redundant navigation to current location"
                      )
                    ) {
                      console.log(e);
                    }
                  });
                }
                if (role === "org_admin") {
                  if (this.$router.path != "/") {
                    this.$router.push("/").catch((e) => {
                      if (
                        e.name !== "NavigationDuplicated" &&
                        !e.message.includes(
                          "Avoided redundant navigation to current location"
                        )
                      ) {
                        console.log(e);
                      }
                    });
                  }
                }
                if (role === "ministry_admin") {
                  if (this.$router.path != "/") {
                    this.$router.push("/").catch((e) => {
                      if (
                        e.name !== "NavigationDuplicated" &&
                        !e.message.includes(
                          "Avoided redundant navigation to current location"
                        )
                      ) {
                        console.log(e);
                      }
                    });
                  }
                }
                if (role === "operator") {
                  if (this.$router.path != "/") {
                    this.$router.push("/").catch((e) => {
                      if (
                        e.name !== "NavigationDuplicated" &&
                        !e.message.includes(
                          "Avoided redundant navigation to current location"
                        )
                      ) {
                        console.log(e);
                      }
                    });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                this.$notify({
                  title: "Хато",
                  message: "Логин ёки пароль нотўгри киритилган",
                  type: "error",
                });
              })
              .finally(() => {
                this.prevent = false;
              });
          } else {
            this.$notify({
              title: "Хато",
              message: "Бўш бўла олмайди",
              type: "error",
            });
          }
        });
      },
    },
  };
  </script>
  
  <style lang="scss">
  .login-view {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 30px;
    justify-content: center;
    position: relative;
    background: #FBFBFB;
  
    .test_inp {
      width: 385px;
      height: 54px;
      border-radius: 15px;
      padding-left: 15px;
      font-family: Raleway;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.44px;
      text-align: left;
      outline: none;
    }
  
    .error_inp {
      border: 1px solid red;
      border-radius: 15px;
    }
  
    .login-view-wrapper {
      width: 1280px;
      height: 714px;
      display: flex;
      border-radius: 42px;
      background: linear-gradient(106.45deg, #000428 -10.44%, #004e92 104.86%);
  
  
      .login-view-left {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 93px;
        min-height: 100%;
  
        .stick {
          width: 59px;
          height: 3px;
          box-shadow: 0px 0px 5px 1px rgba(255, 184, 0, 1);
          background: rgba(255, 184, 0, 1);
          margin-bottom: 55px;
        }
  
        h1 {
          font-family: Raleway;
          font-size: 40px;
          font-weight: 700;
          line-height: 46.96px;
          text-align: left;
          color: rgba(255, 255, 255, 1);
        }
  
        p {
          font-family: Raleway;
          font-size: 20px;
          font-weight: 400;
          line-height: 23.48px;
          text-align: left;
          color: rgba(183, 183, 183, 1);
          margin-top: 7px;
          margin-bottom: 52px;
        }
  
  
        .login-view-card {
          width: 385px;
  
          .inp_wrapper {
  
            display: flex;
            background: #fff;
            border-radius: 15px;
            overflow: hidden;
            width: 385px;
            flex-direction: row-reverse;
            margin-bottom: 20px;
            justify-content: space-between;
            border: 1px solid #000428;
  
            .eye_close {
              margin-right: 19px;
              cursor: pointer;
            }
  
            .eye_open {
              margin-right: 19px;
              cursor: pointer;
            }
  
            .left_inpContent {
              width: 8px;
              height: 54px;
              background: #FFB800;
              transform: translate(-10px, 0);
              transition: 500ms;
            }
  
            .left_inpContentError {
              width: 8px;
              height: 54px;
              background: red;
              transform: translate(-10px, 0);
              transition: 500ms;
            }
  
            .real_inp {
              height: 54px;
              border-radius: 15px;
              padding-left: 15px;
              font-family: Raleway;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.44px;
              text-align: left;
              outline: none;
              border-radius: 15px;
              width: 377px;
              padding-right: 15px;
              border: 1px solid transparent;
            }
  
            // .real_inp:focus {
            //   border: none;
            //   outline: none;
            // }
  
  
            .real_inp:focus+.left_inpContent {
              transform: translate(0, 0);
            }
  
            .real_inp:focus+.left_inpContentError {
              transform: translate(0, 0);
            }
          }
  
          .is-error {
            border: 1px solid red;
          }
  
          .el-form-item__error {
            display: none;
          }
  
          // .el-input__inner {
          //   position: relative;
          //   width: 377px;
          //   height: 54px;
          //   border-radius: 15px;
          //   border: none;
          //   font-family: Raleway;
          //   font-size: 14px;
          //   font-weight: 400 !important;
          //   line-height: 16.44px;
          //   text-align: left;
          //   position: relative;
          //   outline: none;
          //   border: none !important;
          //   background: #fff !important;
          // }
  
          .login-enter-button {
            margin-top: 15px;
            background: rgba(255, 184, 0, 1);
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 600;
            line-height: 18.29px;
            text-align: left;
            border-radius: 15px;
            outline: none;
            color: rgba(0, 0, 0, 1);
            padding: 13px 60px;
            border: none;
            box-shadow: 0px 0px 5px 1px rgba(255, 184, 0, 1);
          }
        }
      }
  
      .login-view-right {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        padding: 20px;
        min-height: 100%;
  
        .login-view-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: max-content;
  
          .login-view-card-container-cover {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: max-content;
            width: 586px;
  
            & #emblem {
              width: 122px !important;
            }
          }
  
          .login-view_img {
            display: flex;
            justify-content: end;
            width: 100%;
            margin-top: 15px;
          }
  
  
          img {
            width: 178px;
          }
  
          h1 {
            margin-top: 30px;
            font-family: Montserrat;
            font-size: 56.03px;
            font-weight: 900;
            line-height: 68.3px;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            margin-bottom: 18px;
          }
  
          p {
            font-family: Montserrat;
            font-size: 21.8px;
            font-weight: 600;
            line-height: 26.57px;
            text-align: center;
            color: rgba(193, 193, 193, 1);
            width: 590px;
          }
        }
      }
  
    }
  }
  
  
  @keyframes LoginLeft {
    0% {
      width: 100%;
      min-width: 100%;
      align-items: center;
      text-align: center;
    }
  
    50% {
      width: 100%;
      min-width: 100%;
      align-items: flex-start;
      text-align: flex-start;
    }
  
    90% {
      width: 50%;
      min-width: 50%;
      align-items: left;
      text-align: left;
    }
  
    100% {
      width: 50%;
      min-width: 50%;
      align-items: left;
      text-align: left;
    }
  }
  
  @keyframes LoginRight {
    0% {
      opacity: 0;
    }
  
    20% {
      opacity: 0;
    }
  
    90% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  </style>
  